import { getCurrentUTCTime, getDate, getTicketsList } from "../utils/Utilities";

export interface TicketProps {
    flyer: string;
    goTo: string;
    ticketLink: string;
    ticketEvent: string;
    tablesLink: string;
    locationLink: string;
    showSince: string;
    showUntil: number;
}

interface IntroTextsProps {
    wantMyTicketAndMoreInfoIntroduction: TicketProps;
}

interface GlobalTextsProps {
    tickets: TicketProps[];
    whatsAppGroupLink: string;
    instagramLink: string;
    tiktokLink: string;
    twitterLink: string;
    picturesLink: string;
}

export const globalTexts: GlobalTextsProps = {
    tickets: [
        {
            flyer: '041024-black.webp',
            goTo: '041024-black',
            ticketLink: 'https://www.passline.com/eventos/fiebre-fiesta-en-black-410',
            ticketEvent: '04/10/24_black',
            tablesLink: 'https://wa.me/message/FFDFCLWPF7KPB1',
            locationLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3285.874584080714!2d-58.4483721!3d-34.556730699999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb5eb0d0f6a89%3A0x64f7cfb36b48c654!2sBlack!5e0!3m2!1ses!2sar!4v1716311869803!5m2!1ses!2sar',
            showSince: '2024-09-16T22:59:00Z',
            showUntil: 20241004
        },
        {
            flyer: '101024-mrjones.webp',
            goTo: '101024-mrjones',
            ticketLink: 'https://www.passline.com/eventos/fiebre-tour',
            ticketEvent: '10/10/24_mrjones',
            tablesLink: 'https://wa.me/message/FFDFCLWPF7KPB1',
            locationLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3142.8812480017787!2d-57.5343712873056!3d-38.02654784611398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9584ddcb892c3015%3A0xcd77be1d1300961e!2sMr.%20Jones!5e0!3m2!1ses!2sar!4v1725996143633!5m2!1ses!2sar',
            showSince: '2024-07-14T21:29:00Z',
            showUntil: 20241010
        },
        {
            flyer: '111024-black.webp',
            goTo: '111024-black',
            ticketLink: 'https://www.passline.com/eventos/fiebre-fiesta-en-black-1110',
            ticketEvent: '11/10/24_black',
            tablesLink: 'https://wa.me/message/FFDFCLWPF7KPB1',
            locationLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3285.874584080714!2d-58.4483721!3d-34.556730699999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb5eb0d0f6a89%3A0x64f7cfb36b48c654!2sBlack!5e0!3m2!1ses!2sar!4v1716311869803!5m2!1ses!2sar',
            showSince: '2024-09-24T22:59:00Z',
            showUntil: 20241011
        },
        {
            flyer: '251024-groove.webp',
            goTo: '251024-groove',
            ticketLink: 'https://www.passline.com/eventos/fiebre-fiesta-en-groove-2510',
            ticketEvent: '25/10/24_groove',
            tablesLink: 'https://wa.me/message/FFDFCLWPF7KPB1',
            locationLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.9688531391407!2d-58.4257572874254!3d-34.57965465611708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb59b4e07a685%3A0x57be92636048e0f9!2sGroove!5e0!3m2!1ses!2sar!4v1720909728659!5m2!1ses!2sar',
            showSince: '2024-09-24T22:59:00Z',
            showUntil: 20241025
        },
    ],
    whatsAppGroupLink: 'https://chat.whatsapp.com/DvTmBLdUjh4KGyb4g3ATdq',
    instagramLink: 'https://www.instagram.com/fiebreflow/',
    tiktokLink: 'https://www.tiktok.com/@fiebre.fiesta',
    twitterLink: 'https://twitter.com/fiebrefiesta',
    picturesLink: 'https://drive.google.com/drive/folders/1dlI4mi7Fxb6Z0O9mF5-LkmpyalajPLyx?usp=share_link'
}

const getInfoVisible = (list: TicketProps[]) => {

    let index = 0

    const targetTimeUTC = new Date(list[index].showSince);

    const currentTimeUTC = new Date(getCurrentUTCTime());

    if ((list[index].showUntil < parseInt(getDate())) || ((currentTimeUTC < targetTimeUTC))) {
        index++
    }

    return list[index]

}

export const introTexts: IntroTextsProps = {
    wantMyTicketAndMoreInfoIntroduction: getInfoVisible(globalTexts.tickets),
    // wantMyTicketAndMoreInfoIntroduction: globalTexts.tickets[0],
}